import React, {useState} from "react";
import {QRedFlagsQueries as QRF} from "app/queries";
import {DefaultTableComponent, T_DefaultTableComponentProps, TDF,} from "app/components/table";
import TransactionDetails from "./RedFlagTransactionDetails"


const renderFunction = (data: QRF.T_RedFlagsTransaction): React.JSX.Element[] => {

    const keyPrefix: string = data.id.toString();
    return [
        <TDF.TextContent value={data.transaction_id} key={`${keyPrefix}-TID`}/>,
        <TDF.TextContent value={"High"} key={`${keyPrefix}-SEV`}/>,
        <TDF.TextContent value={"-"} key={`${keyPrefix}-FLGID`}/>,
        <TDF.TextContent value={`${data.source_name}`} key={`${keyPrefix}-SRCNM`}/>,
        <TDF.TextContent value={"TradeBP"} key={`${keyPrefix}-CN`}/>,
        <TDF.TextContent value={data.operation} key={`${keyPrefix}-OP`}/>,
        <TDF.TextContent value={data.asset_id} key={`${keyPrefix}-ASTID`}/>,
        <TDF.NumericContent value={data.amount_usd} key={`${keyPrefix}-AMTUSD`}/>,
        <TDF.TextContent value={"N/A"} key={`${keyPrefix}-ASGNDTO`}/>,
        <TDF.DateTimeContent dateTime={data.tx_created_at}  key={`${keyPrefix}-RNA`}/>,

    ];
};

const redflagsDataMap = {
    "Txn Id" : "transaction_id",
    "Severity": "severity",
    "Flag Id": "id",
    "Name": "source_name",
    "Company": "company_name",
    "Operation": "operation",
    "Asset Symbol": "asset_id",
    "USD Amount": "amount_usd",
    "Assigned to": "assigned_to",
    "Created At": "created_at",
};

interface RedFlagsTableProps {
    userId?: string
}

export const RedFlagsTable = ({userId}: RedFlagsTableProps): React.JSX.Element => {
    const [open, setOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState<QRF.T_RedFlagsTransaction | null>(null);
    const handleClickOpen = (data: QRF.T_RedFlagsTransaction) => {
        setSelectedTransaction(data); // Set the selected row data
        setOpen(true); // Open the popup
    };
    const handleClose = () => {
        setOpen(false); // Close the popup
        setSelectedTransaction(null); // Clear the selected data
    };

    const redFlagsQuery = QRF.useRedFlagCredentialQuery();

    const headings: string[] = [...Object.keys(redflagsDataMap),];


    const getMenuItemOptionsFn = async (data: any): Promise<TDF.T_MenuItemOptionList> => {
        return new Promise((resolve, reject) => {
            resolve([
                {name: "View Details", onClickHandler: () => handleClickOpen(data)},
            ]);
        });
    };

    const tableData = redFlagsQuery.result || [];

    const tableComponentProps: T_DefaultTableComponentProps = {
        title: "All Red Flags",
        headings: headings,
        isLoading: redFlagsQuery.isResolving,
        initialRowsPerPage: 10,
        tableData: tableData,
        actionColumn: true,
        renderFunction: renderFunction,
        searchOptions: {
            searchKeys: [
               // "severity",
                //"id",
                "asset_type",
                "source_name",
                "asset_id",
                "company_name",
                "status",
                "tx_created_at",
                "operation",
                "assigned",
                "amount_usd",
                "source_address",
                "destination_address",
            ],
        },
        downloadOptions: {
            fileName: "red-flags",
            dataMap: redflagsDataMap,
        },
        actionOptions: {getMenuItemOptionsFn},
    };

    return (
        <>
            <DefaultTableComponent {...tableComponentProps} />
            {selectedTransaction && (
                <TransactionDetails
                    open={open}
                    onClose={handleClose}
                    transactionData={selectedTransaction} // Pass the selected data to the popup
                />
            )}
        </>
    );
};
