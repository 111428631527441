import {RQ} from "app/hooks";
import {ApiConfig} from "app/config";

export const USER_ACCOUNT_APIS = {
    TEAM_MEMBER: ApiConfig.create("user-account/team-member"),
    LINKED_ACCOUNT: ApiConfig.create("user-account/connected-profiles"),
    USER_LIST: ApiConfig.create("user-account"),
    ADD_CONNECTED_PROFILE: ApiConfig.create("user-account/connected-profiles/update"),
};

export type T_TeamMember = {
    last_login: string | null;
    id: string;
    email: string;
    role_name: string;
    first_name: string;
    full_name: string;
    last_name: string | null;
    mobile_no: string | null;
    dob: string;
    image: string | null;
    auth_type: number;
    auth_group_id: number;
    auth_uuid: string | null;
    kyc_auth_id: string | null;
    kyc_is_verified: boolean;
    kyc_result: string | null;
    mfa_secret: string;
    email_verified_at: string | null;
    company_id: number;
    country: string | null;
    is_2fa_active: boolean;
    trade_fees: number | null;
    money_hub_fees: number | null;
    crypto_hub_fees: number | null;
}

export type T_TeamMemberList = T_TeamMember[];

export type T_LinkedAccount = {
    id: string;
    full_name: string;
    company_name: string;
    email: string;
}

export type T_LinkedAccountList = T_LinkedAccount[];

export const useTeamMembersQuery = (userId: String): RQ.T_QueryResult<T_TeamMemberList> => {
    return RQ.useQueryRequest<T_TeamMemberList>({
        url: USER_ACCOUNT_APIS.TEAM_MEMBER.url, 
        queryKey: USER_ACCOUNT_APIS.TEAM_MEMBER.key, 
        enabled: userId !== "",
        params: [userId],
    });
}

export const useLinkedAccountsQuery = (userId: String): RQ.T_QueryResult<T_LinkedAccountList> => {
    return RQ.useQueryRequest<T_LinkedAccountList>({
        url: USER_ACCOUNT_APIS.LINKED_ACCOUNT.url,
        queryKey: USER_ACCOUNT_APIS.LINKED_ACCOUNT.key,
        enabled: userId !== "",
        params: [userId],
        refetchOnWindowFocus: false,
    });
}

export const useUserListQuery = (): RQ.T_QueryResult<any> => {
    return RQ.useQueryRequest<any>({
        url: USER_ACCOUNT_APIS.USER_LIST.url,
        queryKey: [...USER_ACCOUNT_APIS.USER_LIST.key, "list"],
        refetchOnWindowFocus: false,
    });
}

export const useLinkedAccountsAdd = (): RQ.T_MutationResult<any> => {
    return RQ.usePostRequest<any>({
        url: USER_ACCOUNT_APIS.ADD_CONNECTED_PROFILE.url,
    });
}