import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import { getAxiosAPI, postAxiosAPI } from "app/utils/axiosApiRequests";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik } from "formik";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import * as Yup from "yup";
import dayjs, { Dayjs } from "dayjs";
import { SearchInput } from "app/components/inputs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TranslatedForm_schema = () => {
  const { t } = useTranslation();
  const form_schema = Yup.object().shape({
    email: Yup.string()
        .email(t("Invalid email address"))
        .required(t("Email is required")),
    fullName: Yup.string().required(t("Full name is required")),
    dob: Yup.mixed()
        .required("Date of birth is required")
        .test(
          "is-after-1900",
          "Date must be after 01/01/1900",
          (value) => {
            if (!value) return false;
            const lowerBound = dayjs("1899-12-31");
            return (value as Dayjs).isAfter(lowerBound);
          }
        )
        .test(
          "is-18",
          "You must be at least 18 years old",
          (value) => {
            if(!value) return false;
            const today = dayjs();
            const birthDate = value as Dayjs;
            const age = today.diff(birthDate, "year");
            return age >= 18;
          }
        ),
    currency: Yup.string().required(t("Currency is required")),
    language: Yup.string().required(t("Language is required")),
    // asset: Yup.string().required(t("Asset is required")),
    country: Yup.string().required(t("Country is required")),
  });
  return form_schema;
}


interface Country {
  id: string;
  code: string;
  name: string;
  phone_code: string;
  is_supported: boolean;
}

const FlexhubFormView = () => {
  const { t } = useTranslation();
  const form_schema = TranslatedForm_schema();
  const { classes } = useStyles();
  const [currency, setCurrency] = useState([]);
  const [language, setLanguage] = useState([]);
  const [country, setCountry] = useState<Country[]>([]);
  const [asset, setAsset] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const navigate = useNavigate();

  const getCurrency = async () => {
    setLoading(true);
    await getAxiosAPI("/currency")
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setCurrency(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getLanguage = async () => {
    setLoading(true);
    await getAxiosAPI("/language")
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setLanguage(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getAsset = async () => {
    setLoading(true);
    await getAxiosAPI("/asset")
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setAsset(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getCountry = async () => {
    setLoading(true);
    await getAxiosAPI("/country")
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setCountry(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  function formatDate(inputDate: any) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const handleSubmit = async (values: any) => {
    console.log(values);
    setLoading(true);
    const birthDate = dayjs(values.dob).format("YYYY-MM-DD");
    const validity = formatDate(values.validTill);
    const payload = {
      user_email: values.email,
      api_key: values.apiKey,
      currency_id: values.currency,
      language_id: values.language,
      // asset_id: values.asset,
      valid_till: validity,
      redirect_url: values.redirectUrl,
      full_name: values.fullName,
      birthday: birthDate,
      country_code: country.find((c: Country) => c.id === values.country)?.code || "",
    };
    await postAxiosAPI(
      "/flex-hub-transaction-request",
      JSON.stringify(payload)
    )
      .then((res: any) => {
        setLoading(false);
        console.log(res.data);
        if (res.data.code === 200) {
          window.open(
            `/flexhub-payment/${res.data.data.transaction_uid}`,
            "_blank"
          );
        } else {
          setSeverity("error");
        }
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getCurrency();
    getAsset();
    getCountry();
    getLanguage();
  }, []);

  return (
    <Box className={classes.mainBoxForm}>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Typography variant="h4" gutterBottom>
        {t("FLEX-HUB")}
      </Typography>
      <Formik
        initialValues={{
          email: "",
          apiKey: "",
          currency: "",
          language: "",
          // asset: "",
          validTill: "",
          fullName: "",
          dob: null,
          country: "",
          redirectUrl: "",
        }}
        validationSchema={form_schema}
        onSubmit={(values: any) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
          setFieldTouched
        }: any) => (
          <Grid container>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                id="outlined-basic"
                label={t("Email")}
                variant="outlined"
                className={classes.inputBox}
                value={values.email}
                onBlur={handleBlur("email")}
                onChange={handleChange("email")}
              />
              {touched.email && errors.email && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.email}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                id="outlined-basic"
                label={t("Full Name")}
                variant="outlined"
                className={classes.inputBox}
                value={values.fullName}
                onBlur={handleBlur("fullName")}
                onChange={handleChange("fullName")}
              />
              {touched.fullName && errors.fullName && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.fullName}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                id="outlined-basic"
                label={t("API Key")}
                variant="outlined"
                className={classes.inputBox}
                value={values.apiKey}
                onBlur={handleBlur("apiKey")}
                onChange={handleChange("apiKey")}
              />
              {touched.apiKey && errors.apiKey && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.apiKey}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%", margin: "10px 0" }}
                  label="Date Of Birth"
                  value={values.dob}
                  onChange={(newValue) => setFieldValue("dob", newValue)}
                  format="DD/MM/YYYY"
                  maxDate={dayjs().subtract(18, "year")}
                  slotProps={{
                    textField: {
                      onBlur: () => setFieldTouched("dob", true),
                    },
                  }}
                />
                {touched.dob && errors.dob && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.dob}
                  </Typography>
                )}
              </LocalizationProvider>
            </Grid>
            <Grid container>
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                className={classes.gridItemPadding}
              >
                <SearchInput
                  options={currency}
                  label={t("Select Currency")}
                  onSelect={(field: any, value: any) =>
                    setFieldValue(field, value)
                  }
                  displayValue="name"
                  formikValue="currency"
                />
                {touched.currency && errors.currency && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.currency}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                className={classes.gridItemPadding}
              >
                <SearchInput
                  options={language}
                  label={t("Select Language")}
                  onSelect={(field: any, value: any) =>
                    setFieldValue(field, value)
                  }
                  displayValue="name"
                  formikValue="language"
                />
                {touched.language && errors.language && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.language}
                  </Typography>
                )}
              </Grid>
              {/* <Grid
                item
                md={6}
                sm={6}
                xs={12}
                className={classes.gridItemPadding}
              >
                <SearchInput
                  options={asset}
                  label={t("Select Asset")}
                  onSelect={(field: any, value: any) =>
                    setFieldValue(field, value)
                  }
                  displayValue="name"
                  formikValue="asset"
                />
                {touched.asset && errors.asset && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.asset}
                  </Typography>
                )}
              </Grid> */}
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                className={classes.gridItemPadding}
              >
                <SearchInput
                  options={country}
                  label={t("Select Country")}
                  onSelect={(field: any, value: any) =>
                    setFieldValue(field, value)
                  }
                  displayValue="name"
                  formikValue="country"
                />
                {touched.country && errors.country && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.country}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                className={classes.gridItemPadding}
              >
                <TextField
                  id="outlined-basic"
                  label={t("Redirect Url")}
                  variant="outlined"
                  className={classes.inputBox}
                  value={values.redirectUrl}
                  onBlur={handleBlur("redirectUrl")}
                  onChange={handleChange("redirectUrl")}
                />
                {touched.redirectUrl && errors.redirectUrl && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.redirectUrl}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                className={classes.gridItemPadding}
              >
                <TextField
                  id="outlined-basic"
                  label={t("Valid Till")}
                  name="dateTime"
                  type="datetime-local" // Set type to datetime-local
                  value={values.validTill}
                  onBlur={handleBlur("validTill")}
                  onChange={handleChange("validTill")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  margin="normal"
                />
                {touched.validTill && errors.validTill && (
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className={classes.errorMsg}
                  >
                    {errors.validTill}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.buttonBox}>
                  <Button
                    onClick={() => handleSubmit()}
                    variant="contained"
                    className={classes.submitBtn}
                  >
                    {t("Submit")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
};

export default FlexhubFormView;
const useStyles = makeStyles()((theme) => {
  return {
    mainBoxForm: {
      paddingTop: "20px",
    },
    buttonBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: "20px 10px",
    },
    gridItemPadding: {
      padding: "5px 10px",
    },
    routeName: {},
    bottomText: {
      position: "absolute",
      bottom: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    termsText: {
      color: pallete.primaryBlack,
      textDecorationLine: "underline",
      margin: "auto 0",
    },
    checkBoxB: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    mainBox: {
      backgroundColor: "#FCFBFE",
      height: "91vh",
      padding: "30px 50px",
      overflowY: "auto",
      [theme.breakpoints.down("lg")]: {
        height: "100%",
        padding: "80px 15px",
      },
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    orBox: {
      display: "flex",
      flexFlow: "row",
    },
    submitBtn: {
      ...styles.webButton,
      padding: "10px 60px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    rightGrid: {
      padding: "10px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    mainGrid: {},
    formBox: {},
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});
