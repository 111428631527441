import React from "react";
import {
    DefaultTableComponent,
    T_DefaultTableComponentProps,
    TDF,
} from "app/components/table";
import { useLoginHistoryQuery } from "../LoginHistoryQueries";


const renderFunction = (data: any): React.JSX.Element[] => {
    const keyPrefix: string = `login-history-${data.id}`;
    return [

        <TDF.TextContent
            value={data.input_userid}
            key={`${keyPrefix}-input_userid`}
        />,

        <TDF.TextContent
            value={data.client_browser}
            key={`${keyPrefix}-client_browser`}
        />,

        <TDF.TextContent
            value={data.client_os}
            key={`${keyPrefix}-client_os`}
        />,

        <TDF.TextContent
            value={data.client_device}
            key={`${keyPrefix}-client_device`}
        />,
        <TDF.TextContent
            value={data.real_ip ? data.real_ip : "N/A"}
            key={`${keyPrefix}-real_ip`}
        />,
        <TDF.TextContent
            value={data.client_ip}
            key={`${keyPrefix}-client_ip`}
        />,
        <TDF.TextContent
            value={data.location}
            key={`${keyPrefix}-location`}
        />,
        <TDF.TextContent
            value={data.error ? data.error : "N/A"}
            key={`${keyPrefix}-error`}
        />,
        <TDF.TextContent
            value={data.time}
            key={`${keyPrefix}-time`}
        />,
        <TDF.TextContent
            value={data.status ? "Yes" : "No"}
            key={`${keyPrefix}-status`}
        />,

    ];
};

const loginHistoryTableDataMap = {
    "Input User Id": "input_userid",
    "Client Browser": "client_browser",
    "Client OS": "client_os",
    "Client Device": "client_device",
    "Real IP": "real_ip",
    "Client IP": "client_ip",
    "Location": "location",
    "Error": "error",
    "Time": "time",
    "Login successful": "status",
};

export const LoginHistoryTable = () => {
    type LoginHistory = {
        id: number;
        input_userid: string;
        client_browser: string;
        client_os: string;
        client_device: string;
        real_ip?: string;
        client_ip: string;
        location: string;
        error?: string;
        time: string; // Ensure it's a date string
        status: boolean;
    };
    const loginHistoryQuery = useLoginHistoryQuery();
    const headings: string[] = Object.keys(loginHistoryTableDataMap);
    const loginRecords: Array<LoginHistory> = loginHistoryQuery.isResolved
        ? loginHistoryQuery.result
        : [];
    // const tradeReverseOrders = [...tradeOrders].reverse();

    const tableComponentProps: T_DefaultTableComponentProps = {
        title: "Login History",
        headings: headings,
        isLoading: loginHistoryQuery.isResolving,
        initialRowsPerPage: 10,
        tableData: loginRecords,
        actionColumn: true,

        renderFunction: renderFunction,
        searchOptions: {
            searchKeys: [
                "input_userid",
                "client_browser",
                "client_os",
                "client_device",
                "real_ip",
                "location",
                "client_ip",
                "error",
                "time",
                "status",
            ],
        },
        downloadOptions: {
            fileName: "loginHistory.csv",
            dataMap: loginHistoryTableDataMap,
        },
    };

    return <DefaultTableComponent {...tableComponentProps} />;
};
