import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Popover,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Tooltip,
  Divider,
} from "@mui/material";
import { styled } from '@mui/system';
import { makeStyles } from "tss-react/mui";
import { useLocation, useNavigate } from "react-router-dom";
import MessageAlert from "app/components/unsorted/MessageAlert";
import { decodeToken } from "app/utils/commonFunctions";
import { getAxiosAPI, postAxiosAPI } from "app/utils/axiosApiRequests";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppContext, useAuthManager } from "app/hooks";
import { ActionType, useGlobalState } from "app/providers/GlobalStateProvider";
import { useLoader, useNotification } from "app/providers";
import { AddCircleOutlineRounded } from "@mui/icons-material";
import { useUserProfilesQuery, useUserProfileSwitch } from "app/queries/UserAccountQueries";

// Styled components for better customization
const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active'
})<{ active?: number }>(({ active }) => ({
  padding: '12px 16px',
  borderRadius: '8px',
  margin: '4px 8px',
  transition: 'all 0.3s ease',
  position: 'relative',
  backgroundColor: active ? 'rgba(103, 58, 183, 0.9)' : 'transparent',
  '&:hover': {
    backgroundColor: active ? 'rgba(103, 58, 183, 0.9)' : 'rgba(103, 58, 183, 0.1)',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledListItemText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'active'
})<{ active?: number }>(({ active }) => ({
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20px',
  color: active ? '#ffffff' : '#333333',
  transition: 'color 0.3s ease',
}));

const MenuContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
  overflow: 'hidden',
  background: "linear-gradient(155deg, rgb(222, 204, 224) 0%, hsla(256, 35%, 69%, 1) 30%, hsla(263, 58%, 45%, 1) 85%)",
  borderTopRightRadius: '20px',
});

const UserProfileSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px 16px',
  gap: '20px',
});

const LogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
});

const UserDetailsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
});

const UserName = styled(Typography)({
  color: '#333333',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '16px',
  fontWeight: 600,
});

const VaultSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
});

const ScrollableContent = styled(Box)({
  flexGrow: 1, 
  overflowY: 'auto', 
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '10px',
  backgroundColor: '#ffffff',
  '&::-webkit-scrollbar': {
    width: '8px',
    borderRadius: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '12px',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '12px',
  },
});

const ProfileMenuContainer = styled(Box)({
  padding: '8px',
  maxWidth: '280px',
  maxHeight: '400px',
  overflowY: 'auto',
});

const ProfileMenuItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoading' && prop !== 'active'
})<{ isLoading?: boolean, active?: boolean }>(({ isLoading, active }) => ({
  padding: '8px 12px',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  position: 'relative',
  backgroundColor: active ? 'rgba(32, 27, 39, 0.08)' : isLoading ? 'rgba(94, 53, 177, 0.08)' : 'transparent',
  '&:hover': {
    backgroundColor: 'rgba(94, 53, 177, 0.08)',
  },
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  margin: '4px 0',
  ...(isLoading && {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '8px',
      border: '2px solid #5e35b1',
      animation: 'border-dance 1s infinite linear',
    },
    '@keyframes border-dance': {
      '0%': {
        clipPath: 'inset(0 0 calc(100% - 2px) 0)',
      },
      '25%': {
        clipPath: 'inset(0 0 0 calc(100% - 2px))',
      },
      '50%': {
        clipPath: 'inset(calc(100% - 2px) 0 0 0)',
      },
      '75%': {
        clipPath: 'inset(0 calc(100% - 2px) 0 0)',
      },
      '100%': {
        clipPath: 'inset(0 0 calc(100% - 2px) 0)',
      },
    },
  }),
}));

const ProfileInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const ProfileName = styled(Typography)({
  fontWeight: 600,
  fontSize: '14px',
  color: '#333',
  lineHeight: '1.2',
});

const ProfileEmail = styled(Typography)({
  fontSize: '12px',
  color: '#666',
});

export const userMenue = [
  {
    id: 1,
    title: "Settings",
    path: "/settings",
    img: "assets/icons/setting.svg",
    isActive: false,
  },
  {
    id: 2,
    title: "Support",
    path: "/support",
    img: "assets/icons/support.svg",
    isActive: false,
  },
  {
    id: 3,
    title: "Log out",
    path: "/logout",
    img: "assets/icons/logout.svg",
    isActive: false,
  },
];

const CreateVaultDrawer = ({ open, onClose, onSuccess }: any) => {
  const [nickName, setNickName] = useState("");
  const [error, setError] = useState("");
  const notification = useNotification();

  const handleCreate = async () => {
    if (!nickName) {
      setError("Nick Name is required");
      return;
    }
    setError("");
    try {
      const response = await postAxiosAPI("/user-vault",{ nick_name: nickName }, true);
      if (response.data.code === 200) {
        notification.show("Vault created successfully");
        onSuccess();
        onClose();
      } else {
        notification.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      notification.error("An error occurred while creating vault");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Vault</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Nick Name"
          type="text"
          fullWidth
          value={nickName}
          onChange={(e) => setNickName(e.target.value)}
        />
        {
          error && <Typography color="error">{error}</Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleCreate} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SidebarView = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [menue, setMenue] = useState<any>([]);
  const [menueChild, setMenueChild] = useState(userMenue);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeProfileId, setActiveProfileId] = useState<string>("");
  const [switchingProfileId, setSwitchingProfileId] = useState<string | null>(null);
  const decoded: any = decodeToken();
  const [userDetails, setUserDetails] = useState({
    fullname: "",
    companyName: "",
    mobileNumber: "",
    workNumber: "",
    email: "",
    corporateEmail: "",
    countryCity: "",
    address: "",
  });
  const { menus, user } = useAppContext();
  const authManager = useAuthManager();
  const { state, dispatch } = useGlobalState();
  const [vaultDetails, setVaultDetails] = useState<any[]>([]);
  const loader = useLoader();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const userProfilesQuery = useUserProfilesQuery();
  const switchProfileRequest = useUserProfileSwitch();
  const notification = useNotification();

  
  const userProfilesUnordered : Array<any> = Array.isArray(userProfilesQuery.result) ? userProfilesQuery.result : [];

  const userProfiles = userProfilesUnordered.sort((a: any, b: any) => {
    if (a.email === userDetails.email) return -1;
    if (b.email === userDetails.email) return 1;
    return 0;
  });
  
  const switchProfile = async (id: string) => {
    if(userProfiles?.find((profile: any) => profile.id === id).email === userDetails.email) return;
    
    setSwitchingProfileId(id);
    
    switchProfileRequest.EXECUTE({ 
      params: [id],
      onSuccessFn: (data: any) => {
        const { access, refresh } = data;
        authManager.switchUser(access, refresh).then(() => {
          window.location.reload();
        });
      },
      onErrorFn: (error: any) => {
        setSwitchingProfileId(null);
        handleClose();
        notification.error(error.message);
      } 
    });
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleProfileSwitch = async (profileId: string) => {
    await switchProfile(profileId);
  };

  const getUserDetails = async () => {
    const response = await getAxiosAPI("/user-account/details", true)
      .then((res: any) => {
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          const values = res.data.data;
          setUserDetails({
            fullname: values.username,
            companyName: values.company_name,
            mobileNumber: values.mobile_no,
            workNumber: "",
            email: values.email,
            corporateEmail: "",
            countryCity: values.country + ", " + values.city,
            address: "",
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
    console.log(response);
  };

  const handleNavigate = (item: any) => {
    navigate(item.path);
  };

  const handleNavigatePopover = (item: any) => {
    if (item.path === "/logout") {
      authManager.logoutUser();
    } else if (item.path === "/settings") {
      navigate(item.path);
    } else {
      setSnackbarMessage(item.title + " feature is coming soon...");
      setShowSnackbar(true);
    }
  };

  const setActiveTab = (list: any[], path: string) => {
    const newList = list.map((x: any) => {
      if (x.path === path) {
        x.isActive = true;
      } else {
        x.isActive = false;
      }
      return x;
    });
    return newList;
  };

  useEffect(() => {
    if (location.pathname === "/settings" || location.pathname === "/support") {
      const newList = setActiveTab(userMenue, location.pathname);
      setMenueChild(newList);
    }
    getUserDetails();
  }, [location.pathname]);

  useEffect(() => {
    if (decoded && Object.keys(decoded).length && location.pathname) {
      const role = decoded.session.role.name;
      const keys = [10, 15, 9];
      const lists = menus
        .getMenuList()
        .filter((x: any) => !keys.includes(x.key));
      if (role === "Admin") {
        const newList = setActiveTab(lists, location.pathname);
        setMenue(newList);
      } else {
        const newList = setActiveTab(lists, location.pathname);
        setMenue(newList);
      }
    }
  }, [location.pathname]);

  const handleVaultDetails = async () => {
    loader.show();
    await getAxiosAPI("/user-account/vault", true)
      .then((res: any) => {
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          setVaultDetails(res.data.data);
          dispatch({ type: ActionType.SET_VAULT, payload: {
            index: 0,
            id: res.data.data.length ? res.data.data[0].id : '',
          }});
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
    loader.hide();
  };

  useEffect(() => {
    handleVaultDetails();
  }, []);

  useEffect(() => {
    if (userDetails.email) {
      const matchingProfile = userProfiles.find(profile => profile.email === userDetails.email);
      if (matchingProfile) {
        setActiveProfileId(matchingProfile.id);
      }
    }
  }, [userDetails.email, userProfiles]);

  const handleVaultChange = (event: any) => {
    dispatch({ type: ActionType.SET_VAULT, payload: {
      index: event.target.value,
      id: vaultDetails[event.target.value].id,
    }});
  };

  const handleCreateVault = () => {
    setDrawerOpen(true);
  }

  return (
    <MenuContainer>
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <CreateVaultDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} onSuccess={() => handleVaultDetails()}/>

      {/* Fixed header section */}
      <Box sx={{ flex: '0 0 auto' }}>
        <UserProfileSection>
          <LogoContainer>
            <Box
              component="img"
              src="assets/logos/tradeBp.svg"
              alt="TradeBP Logo"
              sx={{ height: '45px', width: 'auto' }}
            />
          </LogoContainer>
          <UserDetailsContainer>
            <Avatar
              sx={{
                width: 55,
                height: 55,
                bgcolor: '#5e35b1',
                color: '#ffffff',
                fontSize: '20px',
                fontWeight: 'bold',
                border: '2px solid #ffffff',
              }}
            >
              {userDetails.fullname ? userDetails.fullname.charAt(0) : "U"}
            </Avatar>
            <UserName>{userDetails.fullname || "User"}</UserName>
            <IconButton
              onClick={handleClick}
              sx={{
                p: 0.5,
                color: '#5e35b1',
                '&:hover': {
                  backgroundColor: 'rgba(94, 53, 177, 0.08)'
                }
              }}
            >
              <KeyboardArrowDownIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          </UserDetailsContainer>
          <VaultSection>
            <FormControl fullWidth size="medium">
              <Select
                labelId="vault-select-label"
                id="vault-select"
                value={vaultDetails.length ? state.vault.index : ''}
                onChange={handleVaultChange}
                sx={{ 
                  bgcolor: '#ffffff', 
                  borderRadius: '10px'
                }}
              >
                {vaultDetails.map((vault, index) => (
                  <MenuItem key={vault.id} value={index}>
                    {vault.nick_name || `Vault ${index + 1}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Tooltip title="Create new vault">
              <IconButton onClick={handleCreateVault} sx={{ marginLeft: "5px" }}>
                <AddCircleOutlineRounded fontSize="medium"/>
              </IconButton>
            </Tooltip>
          </VaultSection>
        </UserProfileSection>
      </Box>
      <ScrollableContent>
        <List>
          {menue.map((item: any, index: number) => (
            <ListItem
            key={`${item.id}-${index}`}
            disablePadding
            onClick={() => handleNavigate(item)}
            >
              <StyledListItemButton active={item.isActive ? 1 : 0}>
                <StyledListItemIcon>
                  <Box
                    component="img"
                    src={`assets/icons/${item.path.split("/")[1]}.svg`}
                    alt={item.label}
                    sx={{ 
                      width: 24, 
                      height: 24,
                      filter: item.isActive ? 'brightness(0) invert(1)' : 'none',
                      transition: 'filter 0.3s ease'
                    }}
                    />
                </StyledListItemIcon>
                <StyledListItemText active={item.isActive ? 1 : 0}>
                  {item.label}
                </StyledListItemText>
              </StyledListItemButton>
            </ListItem>
          ))}
        </List>
      </ScrollableContent>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ p: 1 }}>
          {menueChild.map((item: any) => (
            <ListItem
              key={item.id}
              disablePadding
              onClick={() => handleNavigatePopover(item)}
            >
              <StyledListItemButton active={item.isActive ? 1 : 0}>
                <StyledListItemIcon>
                  <Box
                    component="img"
                    src={item.img}
                    alt={item.title}
                    sx={{ 
                      width: 24, 
                      height: 24,
                      filter: item.isActive ? 'brightness(0) invert(1)' : 'none',
                      transition: 'filter 0.3s ease'
                    }}
                  />
                </StyledListItemIcon>
                <StyledListItemText active={item.isActive ? 1 : 0}>
                  {item.title}
                </StyledListItemText>
              </StyledListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <ProfileMenuContainer>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1, fontSize:"1rem" }} textAlign={"center"}>
            Switch Profile
          </Typography>
          <Divider sx={{ mb: 1 }} />
          {userProfiles.map((profile) => (
            <ProfileMenuItem 
              key={profile.id}
              onClick={() => handleProfileSwitch(profile.id)}
              active={profile.id === activeProfileId}
              isLoading={switchingProfileId === profile.id}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  bgcolor: '#5e35b1',
                  color: '#ffffff',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                {profile.full_name ? profile.full_name.charAt(0).toUpperCase() : "U"}
              </Avatar>
              <ProfileInfo>
                <ProfileName>{profile.full_name || "Unnamed"}</ProfileName>
                <ProfileEmail>{profile.email || ""}</ProfileEmail>
                {profile.company_name && (
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    {profile.company_name}
                  </Typography>
                )}
              </ProfileInfo>
            </ProfileMenuItem>
          ))}
        </ProfileMenuContainer>
      </Popover>
    </MenuContainer>
  );
};

export default SidebarView;

const useStyles = makeStyles()((theme) => {
  return {
    iconWeb: {
      width: "100%",
      height: "45px",
    },
  };
});
