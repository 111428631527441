import React from "react";
import {TabContent} from "app/components/tabs";
import {OpenAssignedFlags} from "./OpenAssignedFlags";
import {ArchiveRedFlags} from "./ArchiveRedFlags";
import {RedFlagsTable} from "./RedFlagsTable";

const RedFlagsView = (): React.JSX.Element => {
    const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
    const tabHeading: string = "Red Flags";
    const resetUrlOnTabChange: boolean = true;

    const RedFlagsTableView = (): React.JSX.Element => {
        const customerId: string | null = queryParams.get("userId");
        return (customerId) ? <RedFlagsTable userId={customerId} /> : <RedFlagsTable />;
    }

    const OpenAssignedToMeFlagsView = (): React.JSX.Element => {
        const customerId: string | null = queryParams.get("userId");
        return (customerId) ? <OpenAssignedFlags /> : <OpenAssignedFlags />;
    }

    const ArchiveRedFlagsView = (): React.JSX.Element => {
        const customerId: string | null = queryParams.get("userId");
        return (customerId) ? <ArchiveRedFlags /> : <ArchiveRedFlags />;
    }

    const tabData = [
        {label: "All Open", component: <RedFlagsTableView />},
        {label: "Open Assigned to me", component: <OpenAssignedToMeFlagsView />},
        {label: "Archive", component: <ArchiveRedFlagsView />},
    ];

    return TabContent({tabHeading, tabData, resetUrlOnTabChange});
};
export default RedFlagsView;
