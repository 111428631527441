import React from "react";
import {
  DefaultTableComponent,
  T_DefaultTableComponentProps,
  TDF,
} from "app/components/table";
import { pallete } from "app/styles";
import {
  T_WalletDetails,
  useWalletDetailsQuery,
} from "app/queries/UserWalletQueries";
import { Box, IconButton } from "@mui/material";
import {
  Description,
  DescriptionRounded,
  InfoOutlined,
  InfoRounded,
  InfoTwoTone,
} from "@mui/icons-material";
import moment from "moment";

const getStatusColor = (status: string): any => {
  switch (status) {
    case "FAILED":
      return pallete.selections.red;
    case "PENDING":
      return pallete.selections.yellow;
    case "COMPLETED":
      return pallete.selections.green;
    default:
      return pallete.selections.brown;
  }
};

const renderFunction = (
  data: T_WalletDetails,
  onTransactionClick: (data: any) => void
): React.JSX.Element[] => {
  const transactionStatusColour = getStatusColor(data.status);

  const additionalData = {
    "Source address": data.source_address,
    "Destination Address": data.destination_address,
    "Network Fee": data.network_fee,
    "Value (USD)": data.value_USD,
    "Last Updated": data.last_updated,
  };

  return [
    <TDF.TextContent value={data.source} />,
    <TDF.TextContent value={data.destination} />,
    <TDF.NumericContent value={data.amount} />,
    <TDF.StatusBadge
      statusText={data.status}
      color={transactionStatusColour}
    />,
    <TDF.TextContent value={data.transaction_hash || "N/A"} />,
    <TDF.TextContent value={data.date} />,
    <TDF.TextContent value={data.type} />,
    <TDF.RawElement
      element={
        <IconButton
          onClick={() => {
            onTransactionClick(additionalData);
          }}
        >
          <InfoOutlined />
        </IconButton>
      }
    />,
  ];
};

const userWalletTransactionDataMap = {
  Source: "source",
  Destination: "destination",
  Amount: "amount",
  Status: "status",
  "Transaction Hash": "transaction_hash",
  Date: "date",
  type: "type",
  Description: "description",
};

const userWalletTransactionDataMapWithAdditional = {
  Date: "date",
  "Last Updated": "last_updated",
  Amount: "amount",
  currency: "asset_id",
  Type: "type",
  Status: "status",
  "Network Fee": "network_fee",
  "Value (USD)": "value_USD",
  "Source Address": "source_address",
  "Destination Address": "destination_address",
  Source: "source",
  Destination: "destination",
};

const getTableData = (data: any) => {
  if (!data) {
    return [];
  }
  const deposits =
    data.deposits?.map((data: any) => ({ ...data, type: "deposit" })) || [];
  const withdrawals =
    data.withdrawals?.map((data: any) => ({ ...data, type: "withdrawals" })) ||
    [];
  const tableDataUnfiltered = [...deposits, ...withdrawals];
  const tableDataSorted = tableDataUnfiltered.sort(
    (a: any, b: any) => b.createdAt - a.createdAt
  );

  const format = "Do MMMM YYYY, h:mm A";

  const tableData = tableDataSorted.map((transaction: any) => {
    const dateObjectDate = moment(transaction.createdAt)
      .format(format)
      .replace(",", " at");
    const dateObjectLastUpdated = moment(transaction.lastUpdated)
      .format(format)
      .replace(",", " at");

    console.log(dateObjectDate);

    return {
      source: transaction.status === 'FAILED' ? "NA" : transaction.sourceAddress,
      destination: transaction.status === 'FAILED' ? "NA" : transaction.destinationAddress,
      amount: transaction.amount,
      status: transaction.status,
      initiator: transaction.createdBy,
      date: dateObjectDate,
      asset_id: transaction.assetId,
      type: transaction.type,

      source_address: transaction.sourceAddress,
      destination_address: transaction.destinationAddress,
      transaction_hash: transaction.txHash,
      network_fee: transaction.networkFee,
      value_USD: transaction.amountUSD,
      last_updated: dateObjectLastUpdated,
    };
  });
  return tableData;
};

type T_WalletTransactionTableProps = {
  wallet_id: String;
  onTransactionClick: (data: any) => void;
};

const WalletTransactionTable = ({
  wallet_id,
  onTransactionClick,
}: T_WalletTransactionTableProps) => {
  const walletTransactionQuery = useWalletDetailsQuery(wallet_id);

  const headings: string[] = Object.keys(userWalletTransactionDataMap);

  const tableData = getTableData(walletTransactionQuery.result);

  const tableComponentProps: T_DefaultTableComponentProps = {
    title: "Wallet Transactions",
    headings: headings,
    isLoading: walletTransactionQuery.isResolving,
    initialRowsPerPage: 10,
    tableData: tableData,
    renderFunction: (data: any) => {
      return renderFunction(data, onTransactionClick);
    },
    searchOptions: {
      searchKeys: ["source", "destination", "amount", "status"],
    },
    downloadOptions: {
      fileName: "user_wallet_transactions.csv",
      dataMap: userWalletTransactionDataMapWithAdditional,
    },
  };

  return <DefaultTableComponent {...tableComponentProps} />;
};

export default WalletTransactionTable;
