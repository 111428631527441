import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { useStyles } from "./settingStyle";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import {getAxiosAPI, postAxiosAPI, putAxiosAPI} from "app/utils/axiosApiRequests";
import { decodeToken } from "app/utils/commonFunctions";
import {useAppContext, useAuthManager} from "../../../../app/hooks";
import ChangeProfileDetailsForm from "./ChangeProfileDetailsForm";
import ChangePasswordForm from "./ChangePasswordForm";
import ChangeImageForm from "./ChangeImageForm";

const Settings = () => {
  const {classes} = useStyles();
  const {user} = useAppContext();
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    mobileNumber: "",
    email: "",
    countryOfIncorporation: "",
    companyAddress: "",
    image: "",
    dob:"",
    role_name:"",
    companyRegistrationNumber:"",
    incorporationDate:"",
  });

  const decoded: any = decodeToken();

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    setLoading(true);
    await getAxiosAPI("/user-account/details", true)
        .then((res: any) => {
          setLoading(false);
          if (res.data.code !== 200) {
            setSnackbarMessage(res.data.message);
            setSeverity("error");
            setShowSnackbar(true);
          } else {
            const values = res.data.data;
            setUserDetails({
              firstName: values.first_name,
              lastName: values.last_name,
              companyName: values.company_name,
              mobileNumber: values.mobile_no,
              email: values.email,
              countryOfIncorporation: values.company_registered_country,
              companyAddress: values.address,
              image: values.image,
              dob:values.dob,
              role_name:values.role_name,
              companyRegistrationNumber:values.company_registration_number,
              incorporationDate:values.company_incorporation_date,
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
          setLoading(false);
          setSnackbarMessage("Failed to fetch details!");
          setShowSnackbar(true);
          setSeverity("error");
        });
  };
  const updateUserDetails = async (values: any) => {
    setLoading(true);
    const birthDate = values.dob instanceof Date ? values.dob.toISOString().split("T")[0]: values.dob.toISOString().split("T")[0];
    const companyIncorporationDate = values.incorporationDate instanceof Date ?
        values.incorporationDate.toISOString().split("T")[0]: values.incorporationDate.toISOString().split("T")[0];
    const passwordChangePath = user.isTeamMember() ? "member" : "user";
    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      company_name: values.companyName,
      mobile_no: values.mobileNumber,
      email:values.email,
      company_registered_country: values.countryOfIncorporation,
      dob: birthDate,
      address: values.companyAddress,
      company_registration_number: values.companyRegistrationNumber,
      company_incorporation_date:companyIncorporationDate,
    };

    await putAxiosAPI(`/${passwordChangePath}-account/details`, JSON.stringify(data), true)
        .then((res: any) => {
          setLoading(false);
          if (res.data.code !== 200) {
            setSnackbarMessage(res.data.message);
            setSeverity("error");
            setShowSnackbar(true);
          } else {
            setSnackbarMessage("User details updated successfully!");
            setSeverity("success");
            setShowSnackbar(true);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setLoading(false);
          setSnackbarMessage("Failed to update user details!");
          setSeverity("error");
          setShowSnackbar(true);
        });
  };
  const authManager = useAuthManager();
  const updatePassword = async (values: any) => {
    setLoading(true);
    const passwordChangePath = user.isTeamMember() ? "member" : "user";
    const data = {
      current_password: values.oldPass,
      new_password: values.newPass,
      confirmed_password: values.confirmPass,
    };
    await postAxiosAPI(`/user-account/password/change`,    JSON.stringify(data),      true  )
        .then((res: any) => {
          setLoading(false);
          if (res.data.code !== 200) {
            setSnackbarMessage(res.data.message);
            setSeverity("error");
            setShowSnackbar(true);
          } else {
            setSnackbarMessage("Password update successfully!");
            setSeverity("success");
            setShowSnackbar(true);
            setTimeout(() => {
              //window.location.reload();
              authManager.logoutUser()
            }, 2000);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setLoading(false);
          setSnackbarMessage("Failed to update password!");
          setSeverity("error");
          setShowSnackbar(true);
        });
  };

  const handleImageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("image", file);

    await postAxiosAPI("/user-account/upload-image", formData, true)
        .then((res: any) => {
          if (res.data.code !== 200) {
            setSnackbarMessage(res.data.message);
            setSeverity("error");
            setShowSnackbar(true);
          } else {
            setSnackbarMessage("Profile image updated successfully!");
            setSeverity("success");
            setShowSnackbar(true);
            getUserDetails(); // Refresh user details
          }
        })
        .catch((err: any) => {
          console.log(err);
          setSnackbarMessage("Failed to upload profile image!");
          setSeverity("error");
          setShowSnackbar(true);
        });
  };
  return (
      <>
        <Loader isLoading={loading}/>
        <MessageAlert
            showBar={showSnackbar}
            message={snackbarMessage}
            severity={severity}
            onClose={() => setShowSnackbar(false)}
        />
        <Box className={classes.mainBox}>
          <Typography variant="h4" color="primary" className={classes.topHeadingWeb}>
            Settings
          </Typography>
          <Card className={classes.mainCard}>
            <Grid container>
              <Grid item lg={3} xs={12} className={classes.userImgGrid}>
                <ChangeImageForm
                  userDetails={userDetails}
                  handleImageUpload={handleImageUpload}
                />
              </Grid>
              <Grid container lg={9} xs={12}>
                <Grid item xs={12} sx={{padding: "20px 10px"}}>
                  <Typography variant="h4" color="primary">
                    Update Profile
                  </Typography>
                </Grid>
                <ChangeProfileDetailsForm
                  userDetails={userDetails}
                  updateUserDetails={updateUserDetails}
                  decoded={decoded}
                />
                <Grid item xs={12} sx={{padding: "20px 10px"}}>
                  <Typography variant="h4" color="primary">
                    Update Password
                  </Typography>
                </Grid>
                <ChangePasswordForm
                  updatePassword={updatePassword}
                  decoded={decoded}
                />
              </Grid>
            </Grid>
          </Card>
        </Box>
      </>
  );
};

export default Settings;
