import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FlexHubOrders } from './FlexHubOrders';
import { FlexHubDailyOrders } from './FlexHubDailyOrders';
import { FlexHubExpiredOrders } from './FlexHubExpiredOrders';

const StyledStack = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  height: 36,
  fontSize: '1rem',
  borderRadius: 12,
}));

export const FlexHubOrdersView = (): React.JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabData = [
    { label: "All", component: <FlexHubOrders /> },
    { label: "Today", component: <FlexHubDailyOrders /> },
    { label: "Expired", component: <FlexHubExpiredOrders /> },
  ];

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StyledStack direction="row" spacing={2}>
        {tabData.map((tab, index) => (
          <StyledChip
            key={tab.label}
            label={tab.label}
            onClick={() => handleTabChange(index)}
            variant={selectedTab === index ? 'filled' : 'outlined'}
            color="primary"
          />
        ))}
      </StyledStack>
      <Box sx={{ mt: 2 }}>
        {tabData[selectedTab].component}
      </Box>
    </Box>
  );
};