import React from "react";
import {useParams} from 'react-router-dom';
import { useFlexPaymentStyles } from "./FlexhubPaymentStyles";
import { useFlexhubPaymentState } from "./FlexhubPaymentHooks";
import { IntegrationView } from "../common";
import { VerifyOTP } from "./VerifyOTP";
import FlexhubTermsView from "./FlexhubTermsView";
import FlexhubFormView from "./FlexhubFormView";
import { InitiateTransactionView } from "./InitiateTransactionView";
import { TransactionView } from "./TransactionView";
import { TransactionCompletedView } from "./TransactionCompletedView";
import { FlexhubEstimatedPriceView } from "./FlexhubEstimatedPriceView";


export const FlexhubPaymentView = (): React.JSX.Element => {
    const { transactionId } = useParams();
    const {classes} = useFlexPaymentStyles();
    const paymentState = useFlexhubPaymentState(transactionId);

    const ActiveComponent = (): React.JSX.Element => {
        if (!transactionId) {
            return <FlexhubFormView />;
        }

        if(!paymentState.isTermsConfirmed) {
            return <FlexhubTermsView paymentState={paymentState}/>
        }

        if (!paymentState.isOtpVerified) {
            return <VerifyOTP paymentState={paymentState} />
        }

        // if(!paymentState.isSimpleKycCompleted) {
        //     return <PersonalDetailsForm {...({classes, paymentState})}/>
        // }

        // if(!paymentState.isSimpleKycOtpVerified) {
        //     return <SimpleKycVerifyOtp paymentState={paymentState} />
        // }

        // if (!paymentState.isKycCompleted) {
        //     return <LivelinessVerification paymentState={paymentState} />
        // }

        if (!paymentState.isTransactionInitiated) {
            return <InitiateTransactionView paymentState={paymentState} />
        }

        if (paymentState.isTransactionEstimatedCompleted) {
            return <TransactionCompletedView {...({classes, paymentState, transactionId})} />
        }
        
        if(paymentState.isTransactionCompleted) {
            return <FlexhubEstimatedPriceView {...({classes, paymentState, transactionId})} />
        }

        if (paymentState.transactionLink){
            return <TransactionView {...({classes, paymentState, transactionId})} />
        }

        return (<></>);
    }

    return ( <IntegrationView       activeComponent={ActiveComponent()} />   );
}
