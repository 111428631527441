import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  SelectChangeEvent, // Import SelectChangeEvent from MUI
} from '@mui/material';
import { QRedFlagsQueries as QRF } from "app/queries";

interface TransactionDetailsProps {
  open: boolean;
  onClose: () => void;
  transactionData: QRF.T_RedFlagsTransaction;
}

const TransactionDetails: React.FC<TransactionDetailsProps> = ({ open, onClose, transactionData }) => {
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState('Unreviewed');
  const [assignTo, setAssignTo] = useState('Unassigned');
  const [isArchiveConfirmationOpen, setIsArchiveConfirmationOpen] = useState(false);

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setStatus(event.target.value);
  };

  const handleAssignToChange = (event: SelectChangeEvent<string>) => {
    setAssignTo(event.target.value);
  };

  const handleSave = () => {
    // Send comment, status, and assignTo to the backend
    const payload = {
      comment,
      status,
      assignTo,
    };
    console.log('Saving data:', payload); // Replace with actual API call
  };

  const handleArchive = () => {
    setIsArchiveConfirmationOpen(true);
  };

  const confirmArchive = () => {
    console.log('Archiving transaction...'); // Replace with actual API call
    setIsArchiveConfirmationOpen(false);
    onClose();
  };

  const cancelArchive = () => {
    setIsArchiveConfirmationOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Transaction ID: {transactionData.transaction_id }
          <Button onClick={onClose} style={{ float: 'right' }}>
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Transfer Details
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Severity</TableCell>
                  <TableCell>High</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Flag ID</TableCell>
                  <TableCell>{"-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>User name</TableCell>
                  <TableCell>{transactionData.source_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company</TableCell>
                  <TableCell>TradeBp</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Date of transaction</TableCell>
                  <TableCell>{transactionData.tx_created_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Time of transaction</TableCell>
                  <TableCell>{transactionData.tx_updated_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Operation</TableCell>
                  <TableCell>{transactionData.operation}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>{transactionData.status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Asset Symbol</TableCell>
                  <TableCell>{transactionData.asset_id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Net Amount</TableCell>
                  <TableCell>{transactionData.net_amount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>USD Amount</TableCell>
                  <TableCell>{transactionData.amount_usd}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Source</TableCell>
                  <TableCell>{transactionData.source_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Source Address</TableCell>
                  <TableCell>{transactionData.source_address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Destination</TableCell>
                  <TableCell>{transactionData.destination_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Destination Address</TableCell>
                  <TableCell>{transactionData.destination_address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Vault</TableCell>
                  <TableCell>Default</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>TxHash</TableCell>
                  <TableCell>{transactionData.transaction_hash}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Chainalysis Category</TableCell>
                  <TableCell>{transactionData.compliance_results["aml"].provider}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Chainalysis status</TableCell>
                  <TableCell>{transactionData.compliance_results["status"]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Assigned to</TableCell>
                  <TableCell>N/A</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            Logs
          </Typography>
          <Paper style={{ padding: '10px', marginBottom: '20px' }}>
            <Typography>              {""}            </Typography>
            <Typography> {""}</Typography>
          </Paper>


          <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
            <TextField
              label="Comments"
              multiline
              rows={4}
              value={comment}
              onChange={handleCommentChange}
              fullWidth
              variant="outlined"
              style={{ marginBottom: '20px' }}
            />
            <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            Status
          </Typography>
            <Select value={status} onChange={handleStatusChange}>
              <MenuItem value="Unreviewed">Unreviewed</MenuItem>
              <MenuItem value="Reviewed">Reviewed</MenuItem>
              <MenuItem value="Pass">Pass</MenuItem>
              <MenuItem value="Block">Block</MenuItem>
            </Select>
            <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            New Assignment
          </Typography>
            <Select value={assignTo} onChange={handleAssignToChange} >
              <MenuItem value="Unassigned">Unassigned</MenuItem>
              <MenuItem value="Prakash">Prakash</MenuItem>
              <MenuItem value="Satwik">Satwik</MenuItem>
              <MenuItem value="Dixit">Dixit</MenuItem>
              <MenuItem value="Amit">Amit</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleArchive}>Archive</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Archive Confirmation Dialog */}
      <Dialog open={isArchiveConfirmationOpen} onClose={cancelArchive} maxWidth="xs">
        <DialogTitle>Confirm Archive</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to close this ticket?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelArchive}>Cancel</Button>
          <Button onClick={confirmArchive} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TransactionDetails;