import React from "react";
import { DefaultTableComponent, T_DefaultTableComponentProps, TDF } from "app/components/table";
import { useLinkedAccountsAdd, useLinkedAccountsQuery, useUserListQuery } from "./UserAccountQueries";
import { Box, Button, Grid, Typography } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { SearchInput } from "app/components/inputs";
import { useNotification } from "app/providers";

type T_LinkedAccount = {
    id: string;
    full_name: string;
    company_name: string;
    email: string;
}

const renderFunction = (data: T_LinkedAccount): React.JSX.Element[] => {
    const keyPrefix: string = data.id.toString();
    return [
        <TDF.TextContent value={data.email} key={`${keyPrefix}-EM`} />,
        <TDF.TextContent value={data.full_name} key={`${keyPrefix}-FN`} />,
        <TDF.TextContent value={data.company_name} key={`${keyPrefix}-CN`} />,
    ]
}

const linkedAccountsDataMap = {
    "Email": "email",
    "Name": "full_name",
    "Company": "company_name",
};

type T_LinkedAccountsViewProps = {
    userId: String;
};

type T_LinkedAccountsTableProps = {
    data: any[];
    isLoading: boolean;
};

const LinkedAccountsTable = (props: T_LinkedAccountsTableProps): React.JSX.Element => {

    
    const headings: string[] = Object.keys(linkedAccountsDataMap);
    const linkedAccounts = props.data ? props.data : [];

    const tableComponentProps: T_DefaultTableComponentProps = {
        title: "Connected Profiles",
        headings: headings,
        isLoading: props.isLoading,
        initialRowsPerPage: 10,
        tableData: linkedAccounts,
        actionColumn: false,
        renderFunction: renderFunction,
        searchOptions: {
            searchKeys: ['email', 'full_name', 'company_name'],
        },
        downloadOptions: {
            fileName: "connected_profiles.csv",
            dataMap: linkedAccountsDataMap,
        },
    }
    return (<DefaultTableComponent {...tableComponentProps} />)
}

const form_schema = Yup.object().shape({
    userAccount: Yup.string().required("User Account is required"),
});

export const LinkedAccountsView = (props: T_LinkedAccountsViewProps): React.JSX.Element => {
    
    const userListQuery = useUserListQuery();
    const connectProfileRequest = useLinkedAccountsAdd();
    const linkedAccountsQuery = useLinkedAccountsQuery(props.userId || "");
    const notification = useNotification();
    const users = userListQuery.isResolved ? userListQuery.result : [];
    const connectedProfiles = linkedAccountsQuery.isResolved ? linkedAccountsQuery.result : [];

    const handleAddProfile = (values: any) => {
        connectProfileRequest.EXECUTE({
            payload: {
                connected_profiles: Array.from(new Set([...connectedProfiles.map((user: any) => user.id), values.userAccount, props.userId])),
            },
            onSuccessFn: (response: any) => {
                linkedAccountsQuery.refetch();
                notification.success("Profile connected successfully");
            },
            onErrorFn: (error: any) => {
                notification.error(error.message || "Error connecting profile");
            },
        });
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Formik
                initialValues={{
                    userAccount: "",
                }}
                validationSchema={form_schema}
                onSubmit={(values: any) => {
                    handleAddProfile(values);
                }}
            >
                {({
                    errors,
                    touched,
                    handleSubmit,
                    setFieldValue,
                }: any) => (
                    <Grid
                        container
                        spacing={2}
                        sx={{ marginBottom: 2 }}
                    >
                        <Grid item xs={10}>
                            <SearchInput
                                options={users}
                                label="Select User Account"
                                onSelect={(field: any, value: any) =>
                                    setFieldValue(field, value)
                                }
                                displayValue="email"
                                formikValue="userAccount"
                            />
                            <Box 
                                sx={{ 
                                    height: '20px', 
                                    marginLeft: 2 
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    sx={{
                                        color: "error.main",
                                    }}
                                >
                                    {(touched.userAccount && errors.userAccount) ? errors.userAccount : ""}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid 
                            item 
                            xs={2} 
                            sx={{ 
                                display: "flex",
                                justifyContent: "flex-start"
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit()}
                                sx={{
                                    height: '50px',
                                    width: '100%',
                                    maxWidth: '180px',
                                    marginTop: "10px",
                                }}
                            >
                                Connect Profile
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Formik>
            <LinkedAccountsTable data={linkedAccountsQuery.isResolved ? linkedAccountsQuery.result : []} isLoading={linkedAccountsQuery.isResolving}/>
        </Box>
    )
}

