import { ApiConfig } from "app/config";
import { RQ } from "app/hooks";

const LOGIN_HISTORY_APIS = {
    GET_LOGIN_HISTORY: ApiConfig.create(
        "login-history"
    ),
};

const useLoginHistoryQuery = () : RQ.T_QueryResult<any> => {
    return RQ.useQueryRequest<any>({
        url: LOGIN_HISTORY_APIS.GET_LOGIN_HISTORY.url,
        queryKey: LOGIN_HISTORY_APIS.GET_LOGIN_HISTORY.key,
        refetchOnWindowFocus: false,
    });
};


export {
    useLoginHistoryQuery,
};
