import React, {useEffect, useState} from "react";
import { T_UseFlexhubPaymentState } from "./FlexhubPaymentHooks";
import { Box, Typography, IconButton, Button, LinearProgress, Divider } from "@mui/material"; // Added CircularProgress import
import RefreshIcon from '@mui/icons-material/Refresh';
import { useFlexhubTransactionAccept, useFlexhubTransactionComplete, useFlexhubTransactionRequest, useFlexhubTransactionVerify } from "./FlexhubPaymentQueries";
import { useNotification } from "app/providers";
import { useTranslation } from "react-i18next";

type T_ViewProps = {
    classes: any;
    paymentState: T_UseFlexhubPaymentState;
    transactionId: string | any;
};

export const TransactionCompletedView = ({classes, paymentState, transactionId}: T_ViewProps): React.JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const { t } = useTranslation();
    const notification = useNotification();
    const transactionRequest = useFlexhubTransactionRequest(transactionId);

    const usecompleteTransaction = useFlexhubTransactionAccept(paymentState.transactionLink?.transaction_id);

    const walletBalance = paymentState.transactionLockedPrice?.received_quantity || 0;
    const amount_in_euro = Math.trunc((paymentState.transactionLockedPrice?.amount_in_euro || 0) * 10000) / 10000;
    const network_fee = Math.trunc((paymentState.transactionLockedPrice?.network_fee || 0) * 10000) / 10000;
    const fee = Math.trunc((paymentState.transactionLockedPrice?.fee || 0) * 10000) / 10000;
    const currency_symbol = transactionRequest.result?.currency_symbol || '';
    const finalAmountCredited = amount_in_euro - (network_fee + fee);
    const isNextButtonDisabled = finalAmountCredited <= 10;


    const handleCompleteTransaction = () => {
        setDisableButton(true);
        setLoading(true);
        usecompleteTransaction.EXECUTE({
            payload: { request_uid: paymentState.transactionLink?.transaction_id },
            onSuccessFn: (data, message) => {
                if(data?.redirect_url) {
                    window.location.href = data.redirect_url;
                } else {
                    setTimeout(() => {
                        handleCompleteTransaction();
                    }, 10000);
                }
            },
            onErrorFn: (data, message) => {
                setLoading(false);
                notification.error(message);
            }
        });
    }
    
    return (
        <Box className={classes.mainCard} style={{ padding: '20px', position: 'relative' }}>
            {
                loading &&
                <>
                    <LinearProgress sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            borderRadius: '8px 8px 0 0',
                            height: '7px',
                        }}
                    />
                    <Typography variant="body2" textAlign={"center"} style={{ marginBottom: '2px', color: '#ff9800' }}>
                        {t("Please don't close this window and wait while we complete the transaction.")}
                    </Typography>
                </>
            }
            <Box className={classes.innerCard} style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
                <Box className={classes.midBoxC1} style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Typography variant="h2" style={{ width: "100%" }} display={"flex"} justifyContent={"space-between"}  color={"#333"}
                        sx={{
                            ['@media (max-width:600px)']: {
                                fontSize: '1.1rem',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                fontSize:"1.3rem",
                            }}
                        >
                            <Box> {t("Received Quantity")} : {walletBalance} {paymentState.transactionLink?.asset}</Box>
                            <Box> {t("Amount in ") + currency_symbol} : {Math.trunc(amount_in_euro * 100) / 100} {currency_symbol}</Box>
                            <Box> {t("Network and Conversion Fees")} : {Math.trunc((network_fee + fee) * 100) / 100} {currency_symbol}</Box>
                        </Box>
                    </Typography>
                    <Divider sx={{width: "100%"}}/>
                    <Typography variant="h2" style={{ width: "100%" }} fontWeight={"bold"}
                        sx={{
                            ['@media (max-width:600px)']: {
                                fontSize: '1.1rem',
                            }
                    }}> {t("Final Amount Credited")} : {Math.trunc(finalAmountCredited * 100) / 100} {currency_symbol}</Typography>
                </Box>
                <Button
                    className={classes.doneButton}
                    style={{ marginTop: '20px' }}
                    onClick={handleCompleteTransaction}
                    disabled={disableButton || isNextButtonDisabled}
                >
                    {t("Accept Settlement")}
                </Button>
            </Box>
        </Box>
    );
};
