import {RQ} from "app/hooks";
import {ApiConfig} from "app/config";

export const REDFlAGS_APIS = {
    CRUD: ApiConfig.create("blockchain-transactions/red-flags"),
    ARCHIVED: ApiConfig.create("blockchain-transactions/archived"),
}

export type T_RedFlagsTransaction = {
  id: string;
  transaction_id: string;
  index: number | null;
  asset_id: string;
  asset_type: string;
  requested_amount: number;
  amount: number;
  fee: number;
  net_amount: number;
  amount_usd: number;
  network_fee: number;
  gas_price: number | null;
  fee_currency: string;
  source_id: string;
  source_address: string;
  source_type: string;
  source_name: string;
  source_sub_type: string;
  destination_id: string;
  destination_address: string;
  destination_type: string;
  destination_name: string;
  destination_sub_type: string;
  destination_address_description: string;
  destination_tag: string;
  transaction_hash: string;
  status: string;
  sub_status: string;
  created_by: string;
  rejected_by: string;
  address_type: string;
  note: string;
  exchange_tx_id: string;
  customer_ref_id: string;
  operation: string;
  num_of_confirmations: number | null;
  tx_created_at: string;
  tx_updated_at: string;
  transaction_type: string | null;
  flag_status: number;
  destinations: any[]; // Replace `any` with a more specific type if needed
  signing_info: {
    signed_by: string[];
    signed_messages: any[]; // Replace `any` with a more specific type if needed
  };
  block_info: Record<string, any>; // Replace `any` with a more specific type if needed
  compliance_results: {
    tr: any | null; // Replace `any` with a more specific type if needed
    aml: {
      provider: string;
      timestamp: number;
      bypassReason: string;
      customerRefId: string;
      screeningStatus: string;
      matchedPrescreeningRule: {
        action: string;
      };
    };
    status: string;
    amlList: {
      provider: string;
      timestamp: number;
      bypassReason: string;
      customerRefId: string;
      screeningStatus: string;
      matchedPrescreeningRule: {
        action: string;
      };
    }[];
    amlRegistration: any | null; // Replace `any` with a more specific type if needed
  };
  authorization_info: {
    logic: string;
    groups: {
      th: number;
      users: Record<string, string>;
    }[];
    allowOperatorAsAuthorizer: boolean;
  };
}
export type T_RedFlagsTransactionList = T_RedFlagsTransaction[];

export const useRedFlagCredentialQuery = (): RQ.T_QueryResult<T_RedFlagsTransactionList> => {
    return RQ.useQueryRequest<T_RedFlagsTransactionList>({
        url: REDFlAGS_APIS.CRUD.url,
        queryKey: REDFlAGS_APIS.CRUD.key,
    });
}

// Mock the useQueryRequest function
// export const useRedFlagCredentialQuery = (): T_RedFlagsApiResponse => {
//   return Data; // Return the mock data directly
// };

export const useCreateMoneyHubCredentialRequest = (): RQ.T_MutationResult<T_RedFlagsTransactionList> => {
    return RQ.usePostRequest<T_RedFlagsTransactionList>({
        url: REDFlAGS_APIS.ARCHIVED.url, mutationKey: REDFlAGS_APIS.ARCHIVED.key, invalidate: true,
    });
}

export const useUpdateMoneyHubCredentialRequest = (): RQ.T_MutationResult<T_RedFlagsTransactionList> => {
    return RQ.usePutRequest<T_RedFlagsTransactionList>({
        url: REDFlAGS_APIS.CRUD.url, mutationKey: REDFlAGS_APIS.CRUD.key, invalidate: true,
    });
}
