import React, { useCallback, useState } from "react";
import { QFlexhubQueries as QCHQ } from "app/queries";
import {
  DefaultTableComponent,
  T_DefaultTableComponentProps,
  TDF,
} from "app/components/table";
import { Box } from "@mui/material";
import { SearchInput } from "app/components/inputs";
import { QUserAccount as QUA } from "app/queries";
import { ExtendValidityButton } from "./ExtendValidityButton";


const flexhubOrdersDataMap = {
  User: "sender_email",
  "Payment ID": "payment_id",
  Currency: "currency_symbol",
  "Asset": "fireblocks_asset_id",
  "Received Quantity": "received_quantity",
  Amount: "amount",
  "Amount in euro": "amount_in_euro",
  Fee: "fee",
  "Network Fee": "network_fee",
  "Date": "created_at",
};


export const FlexHubExpiredOrders = (): React.JSX.Element => {
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const merchantAccountsQuery = QUA.useUserAccountsListQuery();
  const flexhubOrdersQuery = QCHQ.useFlexhubExpiredOrders(selectedUser || "all");
  const headings: string[] = [...Object.keys(flexhubOrdersDataMap), "Extend Validity"];
  const tableData = flexhubOrdersQuery.isResolved ? flexhubOrdersQuery.result : [];
  const userData = merchantAccountsQuery.isResolved ? merchantAccountsQuery.result : [];
  
  const renderFunction = useCallback((data: any): React.JSX.Element[] => {
    return [
      <TDF.TextContent value={data.sender_email} />,
      <TDF.TextContent value={data.payment_id} />,
      <TDF.TextContent value={data.currency_symbol} />,
      <TDF.TextContent value={data.fireblocks_asset_id} />,
      <TDF.NumericContent value={data.received_quantity} />,
      <TDF.NumericContent value={`${data.amount}`} />,
      <TDF.NumericContent value={`${data.amount_in_euro}`} />,
      <TDF.NumericContent value={`${data.fee}`} />,
      <TDF.NumericContent value={data.network_fee} />,
      <TDF.DateTimeContent dateTime={(data?.created_at ? data.created_at.toString() : '')} />,
      <TDF.RawElement element={<ExtendValidityButton data={data} refetch={flexhubOrdersQuery.refetch}/>} />,
    ];
  }, [flexhubOrdersQuery.refetch]);

  const tableComponentProps: T_DefaultTableComponentProps = {
    title: "Flexhub Orders",
    headings: headings,
    isLoading: flexhubOrdersQuery.isResolving,
    initialRowsPerPage: 10,
    tableData: tableData,
    actionColumn: true,
    
    renderFunction: renderFunction,
    searchOptions: {
      searchKeys: [
        "sender_email",
        "currency_symbol",
        "fireblocks_asset_id",
        "amount",
        "fee",
        "network_fee",
        "payment_id",
      ],
    },
    sorterOptions: {
      sortKeys: {
        "Merchant": "receiver_email",
        "Date": "created_at",
      }
    },
    downloadOptions: {
      fileName: "flexhub-orders.csv",
      dataMap: flexhubOrdersDataMap,
    },
  };
  
  
  return (
    <Box>
      <SearchInput
        options={userData}
        onSelect={(_: string, value: string) => {
          setSelectedUser(value);
        }}
        label="User"
        displayValue="email"
        formikValue="user_id"
      />
      <DefaultTableComponent {...tableComponentProps} />
    </Box>
  );
};
