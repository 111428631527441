import React, {useEffect, useState} from "react";
import { T_UseFlexhubPaymentState } from "./FlexhubPaymentHooks";
import { Box, Typography, IconButton, Button, LinearProgress, Divider } from "@mui/material"; // Added CircularProgress import
import RefreshIcon from '@mui/icons-material/Refresh';
import { useFlexhubTransactionComplete, useFlexhubTransactionRequest, useFlexhubTransactionVerify } from "./FlexhubPaymentQueries";
import { useNotification } from "app/providers";
import { useTranslation } from "react-i18next";
import { InfoRounded } from "@mui/icons-material";

type T_ViewProps = {
    classes: any;
    paymentState: T_UseFlexhubPaymentState;
    transactionId: string | any;
};

const useTimerHook = (input: number) : [number, React.Dispatch<React.SetStateAction<number>>, React.Dispatch<React.SetStateAction<boolean>>] => {
    const [timer, setTimer] = useState(input);
    const [stop, setStop] = useState(false);

    useEffect(() => {
        if (stop) {
            return;
        }

        if (timer <= 0) {
            setTimer(input);
        }

        const interval = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [timer]);

    return [timer, setTimer, setStop];
}

export const FlexhubEstimatedPriceView = ({classes, paymentState, transactionId}: T_ViewProps): React.JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [timer, setTimer, setStop] = useTimerHook(10);
    const { t } = useTranslation();
    const notification = useNotification();
    const transactionRequest = useFlexhubTransactionRequest(transactionId);

    const walletBalanceRequest = useFlexhubTransactionVerify(paymentState.transactionLink.transaction_id);
    const usecompleteTransaction = useFlexhubTransactionComplete(paymentState.transactionLink.transaction_id);

    const walletBalance = walletBalanceRequest.result?.received_quantity || 0;
    const amount_in_euro = Math.trunc((walletBalanceRequest.result?.amount_in_euro || 0) * 10000) / 10000;
    const network_fee = Math.trunc((walletBalanceRequest.result?.network_fee || 0) * 10000) / 10000;
    const fee = Math.trunc((walletBalanceRequest.result?.fee || 0) * 10000) / 10000;
    const currency_symbol = transactionRequest.result?.currency_symbol || '';

    const refreshWalletBalance = () => {
        setTimer(10);
        walletBalanceRequest.refetch();
    }

    const handleCompleteTransaction = () => {
        setStop(true);
        setDisableButton(true);
        setLoading(true);
        usecompleteTransaction.EXECUTE({
            payload: { request_uid: paymentState.transactionLink.transaction_id },
            onSuccessFn: (data, message) => {
                if(data) {
                    paymentState.update({
                        isTransactionEstimatedCompleted: true,
                        transactionLockedPrice: {
                            received_quantity: data?.received_quantity,
                            amount_in_euro: data?.amount_in_euro,
                            fee: data?.fee,
                            network_fee: data?.network_fee
                        }
                    });
                } else {
                    setLoading(false);
                    notification.error(message);
                }
            },
            onErrorFn: (data, message) => {
                setLoading(false);
                notification.error(message);
            }
        });
    }
    
    return (
        <Box className={classes.mainCard} style={{ padding: '20px', position: 'relative' }}>
            {
                loading &&
                <>
                    <LinearProgress sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            borderRadius: '8px 8px 0 0',
                            height: '7px',
                        }}
                    />
                    <Typography variant="body2" textAlign={"center"} style={{ marginBottom: '2px', color: '#ff9800' }}>
                        {t("Please don't close this window and wait while we complete the transaction.")}
                    </Typography>
                </>
            }
            <Typography variant="body1" style={{ color: '#666', marginLeft: "auto" }}>{t("refreshing in")} {timer} {t("seconds")}</Typography>
            {/* <Typography variant="h4" textAlign={"center"} style={{ marginBottom: '10px', color: '#333' }}>Verify Transaction</Typography> */}
            <Box className={classes.innerCard} style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
                <Box className={classes.midBoxC1} style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Typography variant="h2" style={{ width: "100%" }} display={"flex"} justifyContent={"space-between"}  color={"#333"}
                        sx={{
                            ['@media (max-width:600px)']: {
                                fontSize: '1.1rem',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                fontSize:"1.3rem",
                            }}
                        >
                            <Box> {t("Estimated")} {t("Received Quantity")} : {walletBalance} {paymentState.transactionLink.asset}</Box>
                            <Box> {t("Estimated")} {t("Amount in ") + currency_symbol} : {Math.trunc(amount_in_euro * 100) / 100} {currency_symbol}</Box>
                            <Box> {t("Estimated")} {t("Network and Conversion Fees")} : {Math.trunc((network_fee + fee) * 100) / 100} {currency_symbol}</Box>
                        </Box>
                        <Box>
                            <IconButton onClick={refreshWalletBalance} disabled={disableButton}>
                                <RefreshIcon />
                            </IconButton>
                        </Box>
                    </Typography>
                    <Divider sx={{width: "100%"}}/>
                    <Typography variant="h2" style={{ width: "100%" }} fontWeight={"bold"}
                        sx={{
                            ['@media (max-width:600px)']: {
                                fontSize: '1.1rem',
                            }
                    }}> {t("Estimated")} {t("Final Amount Credited")} : {Math.trunc((amount_in_euro - (network_fee + fee)) * 100) / 100} {currency_symbol}</Typography>
                </Box>
                <Box sx={{ 
                    backgroundColor: '#fff8e1', 
                    padding: '16px', 
                    borderRadius: '8px',
                    border: '1px solid #ffe082',
                    marginBottom: '24px',
                    maxWidth: '800px',
                    '@media (max-width:600px)': {
                        maxWidth: '100%',
                    }
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '8px', marginBottom: '12px', alignContent: 'center' }}>
                        <InfoRounded sx={{ color: '#f57c00', fontSize: '20px', marginTop: '2px' }} />
                        <Typography variant="subtitle2" fontWeight="bold" color="#f57c00" marginTop={0.5}>
                            {t("Disclaimer")}
                        </Typography>
                    </Box>
                    <Box sx={{ pl: 1, borderLeft: '3px solid #ffe082' }}>
                        <Typography variant="body2" sx={{ marginBottom: '8px', color: '#555' }}>
                            1. {t("Verify the transaction details above and click on the \"Confirm\" button to complete the transaction.")}
                        </Typography>
                        <Typography variant="body2" sx={{ marginBottom: '8px', color: '#555' }}>
                            2. {t("If the received quantity is incorrect please wait until received quantity gets updated.")}
                        </Typography>
                        <Typography variant="body2" sx={{ marginBottom: '12px', color: '#555' }}>
                            3. {t("The estimated network fee, estimated fee, and estimated amount displayed on this page are for informational purposes only and may be subject to change during or after the final transaction. The final, updated details will be provided on the subsequent page, and the actual fees and amounts may differ from those initially estimated.")}
                        </Typography>
                    </Box>
                </Box>
                <Button
                    className={classes.doneButton}
                    style={{ marginTop: '20px' }}
                    onClick={handleCompleteTransaction}
                    disabled={disableButton}
                >
                    {t("Confirm")}
                </Button>
            </Box>
        </Box>
    );
};
