import React from "react";
import {DefaultTableComponent, T_DefaultTableComponentProps, TDF} from "app/components/table";
import {get2FAStatusProps, getKycStatusProps} from "app/utils";
import {useAppContext} from "app/hooks";
import {useNotification} from "app/common";
import { useTeamMembersQuery, T_TeamMember } from "./UserAccountQueries";

const renderFunction = (data: T_TeamMember): React.JSX.Element[] => {
    const keyPrefix:string = data.id.toString();
    return [
        <TDF.TextContent value={data.email} key={`${keyPrefix}-EM`} />,
        <TDF.TextContent value={data.full_name} key={`${keyPrefix}-FN`} />,
        <TDF.NumericContent value={`${data.mobile_no}`} key={`${keyPrefix}-PH`} />,
        <TDF.TextContent value={data.role_name || 'N/A'} key={`${keyPrefix}-RLN`} />,

        <TDF.StatusBadge {...get2FAStatusProps(data.is_2fa_active)} key={`${keyPrefix}-2FA`} />,
        <TDF.StatusBadge {...getKycStatusProps(data)} key={`${keyPrefix}-KYC`} />,


        <TDF.DateTimeContent dateTime={`${data.dob}`} key={`${keyPrefix}-DOB`}  format={'Do MMMM YYYY'}/>,
        <TDF.DateTimeContent dateTime={data.email_verified_at} key={`${keyPrefix}-DT`} />,
        <TDF.DateTimeContent dateTime={data.last_login} key={`${keyPrefix}-DTLL`} />,
    ]
}

const teamMembersDataMap = {
    "Email": "email",
    "Name": "first_name",
    "Mobile": "mobile_no",
    "Role": "role_name",
    "2FA": "is_2fa_active",
    "KYC": "kyc_is_verified",
    "Date Of Birth": "dob",
    "Date": "email_verified_at",
    "Last Login": "last_login",
};



type T_TeamMembersViewProps = {
    userId: String;
};

export const TeamMembersView = (props: T_TeamMembersViewProps): React.JSX.Element => {
    const {user} = useAppContext();

    const teamMembersQuery= useTeamMembersQuery(props.userId || "");
    const headings: string[] = Object.keys(teamMembersDataMap);
    const teamMembers = teamMembersQuery.isResolved ? teamMembersQuery.result:[];

    const tableComponentProps: T_DefaultTableComponentProps = {
        title: "Team Members",
        headings: headings,
        isLoading: teamMembersQuery.isResolving,
        initialRowsPerPage: 10,
        tableData: teamMembers,
        actionColumn: !user.isTeamMember(),
        renderFunction: renderFunction,
        searchOptions: {
            searchKeys: ['email', 'full_name', 'mobile'],
        },
        downloadOptions: {
            fileName: "team-members.csv",
            dataMap: teamMembersDataMap,
        },
    }
    return (<DefaultTableComponent {...tableComponentProps} />)

}
