import React, { useState } from "react";
import moment from "moment";
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { margin, padding, styled } from '@mui/system';
import { QFlexhubQueries as QCHQ } from "app/queries";
import { useNotification } from "app/providers";

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialogTitle-root': {
        backgroundColor: "rgba(103, 58, 183, 0.9)",
        color: 'white',
    },
    '& .MuiDialogContent-root': {
        padding: '20px',
        marginTop: '0px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    '& .MuiDialogActions-root': {
        padding: '16px',
    },
}));

const FormField = styled(Box)({
    marginBottom: '16px',
    width: '100%',
});

const FieldLabel = styled(Box)({
    fontWeight: 500,
    marginBottom: '8px',
});

export const ExtendValidityButton = ({ data, refetch }: { data: any, refetch?: () => void }): React.JSX.Element => {
    const [open, setOpen] = useState(false);
    const [extendDate, setExtendDate] = useState<moment.Moment | null>(null);
    const useFlexhubExtexdValidity = QCHQ.useFlexhubExtexdValidity(data.uid);
    const notification = useNotification();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setExtendDate(null);
    };

    const handleSubmit = async () => {
        if (!extendDate) return;
        if (!extendDate.isAfter(moment())) {
            notification.error('Please select a valid date and time in the future');
            return;
        }

        useFlexhubExtexdValidity.EXECUTE({
            payload: {
                valid_till: extendDate.format('YYYY-MM-DD HH:mm:ss')
            },
            onSuccessFn: (_: any, message: string) => {
                notification.success("Validity extended successfully");
                if (refetch) refetch();
                handleClose();
            },
            onErrorFn: (_: Error | any, message: string) => {
                notification.error(message);
            }
        })
    };

    const minDate = moment().add(1, 'days');

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleOpen}
            >
                Proceed
            </Button>

            <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Extend Validity</DialogTitle>
                <DialogContent>
                    <FormField>
                        <FieldLabel>Payment ID</FieldLabel>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={data.payment_id}
                            disabled
                        />
                    </FormField>

                    <FormField>
                        <FieldLabel>Created Date</FieldLabel>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={data.created_at ? moment(data.created_at).format('YYYY-MM-DD HH:mm:ss') : ''}
                            disabled
                        />
                    </FormField>

                    <FormField>
                        <FieldLabel>Extend Validity Until</FieldLabel>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                value={extendDate}
                                onChange={(newValue) => setExtendDate(newValue)}
                                disablePast
                                minDate={minDate}
                                format="DD/MM/YYYY HH:mm:ss"
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        variant: 'outlined',
                                        error: !extendDate,
                                        helperText: !extendDate ? 'Please select a valid date and time' : '',
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </FormField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        disabled={!extendDate}
                    >
                        {'Submit'}
                    </Button>
                </DialogActions>
            </StyledDialog>
        </>
    );
};