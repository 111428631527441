import React from "react";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import { useStyles } from "./settingStyle";
import { Formik } from "formik";
import {MuiPhone} from "../../../../app/components/inputs/fields/MobileNumberWIthFlag";
import * as Yup from "yup";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const form_schema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  dob: Yup.string()
    .required("Date of birth is required")
    .test("dob", "You must be at least 18 years old", (value) => {
      const today = dayjs();
      const dob = dayjs(value);
      const age = today.diff(dob, "year");
      return age >= 18;
    }),
  companyName: Yup.string().required("Company Name is required"),
  incorporationDate: Yup.string().required("Date of Company Incorporation is required"),
  countryOfIncorporation: Yup.string().required("Company Registered in Country is required"),
  companyAddress: Yup.string().required("Company Address is required"),
  companyRegistrationNumber: Yup.string().required("Company Registration Number is required"),
  mobileNumber: Yup.string().required("Phone Number is required"),
});

const ChangeProfileDetailsForm = ({ userDetails, updateUserDetails, decoded }:any) => {
  const { classes } = useStyles();
  const isEditable = userDetails.role_name === "Admin" || userDetails.role_name === "Compliance";
  userDetails.dob = userDetails.dob ? dayjs(userDetails.dob) : "";
  userDetails.incorporationDate = userDetails.incorporationDate ? dayjs(userDetails.incorporationDate) : "";
  return (
    <Formik
      initialValues={userDetails}
      validationSchema={form_schema}
      enableReinitialize={true}
      onSubmit={(values):any => {updateUserDetails(values);}}
    >
      {({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
        }:any) => (
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    First Name<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <TextField
                    label="First Name"
                    variant={"outlined"}
                    className={classes.inputBox}
                    value={values.firstName}
                    disabled={!isEditable || decoded.session.auth_type !== 1}
                    onChange={handleChange("firstName")}
                    onBlur={handleBlur("firstName")}
                    autoComplete="off"
                  />
                    {touched.firstName && errors.firstName && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.firstName}
                          </Typography>
                        )}
                </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Last Name<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <TextField
                    label="Last Name"
                    variant={"outlined"}
                    className={classes.inputBox}
                    value={values.lastName}
                    disabled={!isEditable || decoded.session.auth_type !== 1}
                    onChange={handleChange("lastName")}
                    onBlur={handleBlur("lastName")}
                    autoComplete="off"
                  />
                    {touched.lastName && errors.lastName && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.lastName}
                          </Typography>
                        )}
                </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Email<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <TextField
                    label="Email"
                    variant="outlined"
                    disabled={!isEditable || decoded.session.auth_type !== 1}
                    className={classes.inputBox}
                    value={values.email}
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                  />
                    {touched.email && errors.email && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.email}
                          </Typography>
                        )}
                </Grid>
                  <Grid
                    item
                      md={6}
                      sm={6}
                      xs={12}
                      className={classes.imputGrids}
                    >
                    <Typography color="primary" variant="body2">
                    Date Of Birth<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: "100%", margin: "10px 0" }}
                          label={"Date Of Birth"}
                          value={values.dob}
                          disabled={!isEditable || decoded.session.auth_type !== 1}
                          onChange={(newValue) => {
                            // Update Formik's state for the 'dob' field with the new date value
                            setFieldValue(
                              "dob",
                              newValue
                            );
                          }}
                        />
                      </LocalizationProvider>
                      {touched.dob && errors.dob && (
                        <Typography
                          variant="subtitle2"
                          align="left"
                          className={classes.errorMsg}
                        >
                            {errors.dob}
                        </Typography>
                      )}
                    </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Company Name<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    className={classes.inputBox}
                    value={values.companyName}
                    disabled={!isEditable || decoded.session.auth_type !== 1}
                    onChange={handleChange("companyName")}
                    onBlur={handleBlur("companyName")}
                  />{touched.companyName && errors.companyName && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.companyName}
                          </Typography>
                        )}
                </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Phone Number<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <MuiPhone
                    placeholder="Mobile Number"
                    value={values.mobileNumber}
                    disabled={!isEditable || decoded.session.auth_type !== 1}
                    onChange={handleChange("mobileNumber")}
                    onBlur={handleBlur("mobileNumber")}
                    style={{
                      "--react-international-phone-flag-width": "40px",
                      "--react-international-phone-flag-height": "32px",
                      "--react-international-phone-height": "50px",
                      "--react-international-phone-width": "full",
                      "--react-international-phone-font-size": "15px",
                      paddingLeft: "0px",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      width: "102%",
                    }as React.CSSProperties}
                  />
                    {touched.mobileNumber && errors.mobileNumber && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.mobileNumber}
                          </Typography>
                        )}
                </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                     Company Registration Number<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <TextField
                    label="Company Registration Number"
                    variant={"outlined"}
                    className={classes.inputBox}
                    value={values.companyRegistrationNumber}
                    disabled={!isEditable || decoded.session.auth_type !== 1}
                    onChange={handleChange("companyRegistrationNumber")}
                    onBlur={handleBlur("companyRegistrationNumber")}
                    autoComplete="off"
                  />
                    {touched.companyRegistrationNumber && errors.companyRegistrationNumber && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.companyRegistrationNumber}
                          </Typography>
                        )}
                </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Country of Incorporation<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <TextField
                    label="Country of Incorporation"
                    variant="outlined"
                    className={classes.inputBox}
                    value={values.countryOfIncorporation}
                    disabled={!isEditable || decoded.session.auth_type !== 1}
                    onChange={handleChange("countryOfIncorporation")}
                    onBlur={handleBlur("countryOfIncorporation")}
                  />
                    {touched.countryOfIncorporation && errors.countryOfIncorporation && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.countryOfIncorporation}
                          </Typography>
                        )}
                </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Company Address<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <TextField
                    label="Company Address"
                    variant="outlined"
                    className={classes.inputBox}
                    value={values.companyAddress}
                    disabled={!isEditable || decoded.session.auth_type !== 1}
                    onChange={handleChange("companyAddress")}
                    onBlur={handleBlur("companyAddress")}
                  />
                    {touched.companyAddress && errors.companyAddress && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.companyAddress}
                          </Typography>
                        )}
                </Grid>
                  <Grid
                    item
                      md={6}
                      sm={6}
                      xs={12}
                      className={classes.imputGrids}
                    >
                      <Typography color="primary" variant="body2">
                    Date of incorporation of the company<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: "100%", margin: "10px 0" }}
                          label={"Date of Incorporation"}
                          value={values.incorporationDate}
                          disabled={!isEditable || decoded.session.auth_type !== 1}
                          onChange={(newValue) => {
                            // Update Formik's state for the 'incorporationDate' field with the new date value
                            setFieldValue(
                              "incorporationDate",
                              newValue
                            );
                          }}
                        />
                      </LocalizationProvider>
                      {touched.incorporationDate && errors.incorporationDate && (
                        <Typography
                          variant="subtitle2"
                          align="left"
                          className={classes.errorMsg}
                        >
                            {errors.incorporationDate}
                        </Typography>
                      )}
                    </Grid>
                <Grid item xs={12}>
                    {!isEditable && (
                        <Typography color="red" variant="body2">
                        Please contact your Administrator or Compliance team for any changes.
                      </Typography>
                        )}
                  <Box className={classes.saveBtnBox}>
                    <Button
                      disabled={!isEditable || decoded.session.auth_type !== 1}
                      type="submit"
                      variant="contained"
                      className={classes.saveBTN}
                    >
                      Save Changes
                    </Button>
                  </Box>
                </Grid>
              </Grid>
          </form>
      )}
    </Formik>
  );
};
export default ChangeProfileDetailsForm