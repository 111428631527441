import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { T_UseAccountStatus } from "app/hooks";
import { usePrivateLayoutStyles } from "./PrivateLayoutStyles";
import { Grid } from "@mui/material";
import SidebarView from "./SidebarView";
import { HeaderView } from "./HeaderView";

export const ApplicationView = ({
  accountStatus,
  updateAccountStatus,
}: T_UseAccountStatus): JSX.Element => {
  const { classes } = usePrivateLayoutStyles();
  const [title, setTitle] = useState<string>("");

  return (
    <>
      <Grid container className={classes.webGrid}>
        <Grid item sm={2} className={classes.sideBarGrid}>
          <SidebarView />
        </Grid>
        <Grid item sm={10} className={classes.childGrid}>
          <Outlet />
        </Grid>
      </Grid>
      {/* This is the mobile layout of the application*/}
      <Grid container className={classes.mobileGrid}>
        <Grid item xs={12} className={classes.childGrid}>
          <HeaderView title={title} />
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};
