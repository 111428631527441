import React from "react";
import {QMoneyhubUserQueries as QMHU} from "app/queries/index";
import { QRedFlagsQueries as QRF } from "app/queries";
import {DefaultTableComponent, T_DefaultTableComponentProps, TDF} from "app/components/table";
import {getKycStatusProps, redirectTo} from "app/utils";

const renderFunction = (data: QMHU.T_MoneyHubUserAccount): React.JSX.Element[] => {
  return [
    <TDF.TextContent value={data.email} />,
    <TDF.TextContent value={`${data.full_name}`} />,
    <TDF.NumericContent value={`${data.mobile}`} />,
    <TDF.DateTimeContent dateTime={`${data.dob}`} />,
    <TDF.StatusBadge {...getKycStatusProps(data)} />,
    <TDF.DateTimeContent dateTime={data.email_verified_at} />,
  ]
}

const moneyhubAccountsDataMap = {
  "Email": "email",
  "Full Name": "full_name",
  "Mobile": "mobile",
  "Date Of Birth": "dob",
  "Verified": "kyc_is_verified",
  "Date": "email_verified_at",
};


export const OpenAssignedFlags = (): React.JSX.Element => {
  const moneyhubAccountsQuery = QMHU.useUseMoneyHubUserAccountsListQuery();
  const headings: string[] = Object.keys(moneyhubAccountsDataMap);
  const tableData = moneyhubAccountsQuery.isResolved ? moneyhubAccountsQuery.result : [];

  const getMenuItemOptionsFn = async (data: QMHU.T_MoneyHubUserAccount): Promise<TDF.T_MenuItemOptionList> => {
    const viewAllTransactions = () => redirectTo({query: {mhid: data.id}});
    return Promise.resolve([
      {name: "View All Transactions", onClickHandler: viewAllTransactions},
    ]);
  }

  const tableComponentProps: T_DefaultTableComponentProps = {
    title: "Moneyhub Accounts",
    headings: headings,
    isLoading: moneyhubAccountsQuery.isResolving,
    initialRowsPerPage: 10,
    tableData: tableData,
    actionColumn: true,

    renderFunction: renderFunction,
    searchOptions: {
      searchKeys: ['email', 'full_name', 'mobile',],
    },
    downloadOptions: {
      fileName: "moneyhub-user-accounts.csv",
      dataMap: moneyhubAccountsDataMap,
    },
    // actionOptions: {getMenuItemOptionsFn}

  }

  return (<DefaultTableComponent {...tableComponentProps} />)
}
